//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductSizes from "@/components/pages/productCard/ProductContent/Components/ProductSizes/index";
import ProductBuyFavorite from "@/components/pages/productCard/ProductContent/Components/ProductBuyFavorite/index";
import ProductPrice from "@/components/pages/productCard/ProductContent/Components/ProductPrice/index";
import ProductPdp from "@/components/pages/productCard/ProductContent/Components/ProductPdp/index";
import {mapGetters} from "vuex";
import HalykInfo from "~/components/common/halykInfo/index.vue";
import BnplInfo from "~/components/pages/productCard/ProductSidebar/Components/BnplInfo.vue";
export default {
    name: "ProductSidebar",
    components: {BnplInfo, HalykInfo, ProductPdp, ProductPrice, ProductBuyFavorite, ProductSizes},
    props: {
        product: {
            type: Object,
            default: () => {}
        }
    },

    computed: {
        ...mapGetters({
            widgetsDataLoading: 'dynamicPage/getWidgetsDataLoading',
        }),
        productDivision() {
            return _get(this, 'product.division', '')
        },
        productGender() {
            let genders = _get(this, 'product.gender', [])
            return genders.length === 1 ? genders[0] : false
        },
        productDisplayName () {
            return _get(this, 'product.displayName', '')
        },
        productDefaultColor () {
            return _get(this, 'product.defaultColor', '')
        },
    },
}
